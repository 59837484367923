import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import DeleteCard from "../KeyManagement/DeleteCardModal";
import AddBank from "../KeyManagement/AddBankModal";
import AddCard from "../KeyManagement/AddCardModal";
import BankDetail from "../KeyManagement/BankDetailModal";
import CardDetail from "../KeyManagement/CardDetailModal";
import SendPayment from "../KeyManagement/SendPaymentModal";
import AddMoneyToWallet from "../KeyManagement/AddMoneyToWalletModal";
import WithdrawMoneyToBank from "../KeyManagement/WithdrawMoneyToBankModal";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../../redux/store"
import { getMerchantBanks } from '../../../redux/features/bank/bankAction';
import { getMerchantCards } from '../../../redux/features/card/cardAction';
import { getMerchantWalletBalance } from '../../../redux/features/wallet/walletAction';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import RequestMoney from "../KeyManagement/RequestMoneyModal";

const WalletBank = () => {
  // for adding bank 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // for deleting card
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  // for adding card 
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // for bank detail popup
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // for card detail popup
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // for send payment modal
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);


  // for send payment modal
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);


  // for withdraw money to bank modal
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  // for withdraw money to bank modal
  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);


  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };


  const dispatch: AppDispatch = useDispatch()
  const [bankDetails, setBankDetails] = useState()
  const [cardDetails, setCardDetails] = useState()
  const [bankLoader, setBankLoader] = useState(false)
  const [cardLoader, setCardLoader] = useState(false)
  const [walletLoader, setWalletLoader] = useState(false)
  const bankData = useSelector((state: any) => state.bank)
  const cardData = useSelector((state: any) => state.card)
  const balanceData = useSelector((state: any) => state.wallet)

  useEffect(() => {
    dispatch(getMerchantWalletBalance())
  }, [walletLoader])

  useEffect(() => {
    dispatch(getMerchantBanks())
  }, [bankLoader])

  useEffect(() => {
    const payload: any = {
      customer_id: localStorage.getItem("customerId")
    }




    dispatch(getMerchantCards(payload))
  }, [cardLoader])

  return (
    <>
      <div className="dbRight">
        <div className="mainHd">Wallet & Banks</div>
        <div className="newKeyBox walletBox">
          <div className="wll_left">
            <div className="wllIcon">
              <img src="/images/wallet_pic.svg" alt="wallet icon" />
            </div>
            <div className="wllText">
              <p className="sbTTL">Total Amount in Wallet</p>
              {balanceData.loading ?
                <p className="wal_amt"><Skeleton height={35} style={{ backgroundColor: "trasparent" }} /></p>
                : <p className="wal_amt">${balanceData?.data?.balance}</p>}
            </div>
          </div>
          <div className="wll_right">
            <div className="BTN_wrp">
              <button onClick={handleShow9} className="bt">Request Money</button>
              <button onClick={handleShow6} className="bt">Send Payment</button>
              <button onClick={handleShow7} className="bt">Add Money To Wallet</button>
              <button onClick={handleShow8} className="bt">Transfer To Bank</button>
            </div>
          </div>
        </div>
        <div className="whtBox mt20">
          <div className="wallDetailWrp">
            <h3 className="sbTtl">Linked Banks</h3>
            <ul className="bankDet">
              {bankData?.loading ? [...Array(5)].map((d, i) => {
                return (
                  <li>
                    <div style={{ width: "20rem" }}>
                      <Skeleton height={20} />
                    </div>
                  </li>
                )
              }) : bankData?.data?.length > 0 ? bankData?.data?.map((data: any, i: number) => {
                return (
                  <li>
                    <div className="bdLeft">
                      {/* <label className="chkCus">
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                      </label> */}
                      <button onClick={() => { setBankDetails(data); handleShow4(); }}>{data?.account?.slice(0, 6) + 'XXXXXXXXXX'}-{data?.last4}</button>
                      {data?.default_for_currency ? <div className="date flexBox itemCenter" style={{ marginLeft: "1rem", height: "1.5rem", fontSize: "12px" }}>Default</div> : ""}
                    </div>
                    {/* <div className="bdRight">
                      <div className="bnkLogo">
                        <img src="/images/citi_logo.png" alt="bankLogo" />
                      </div>
                    </div> */}
                  </li>
                )
              }) : "No Bank found..."}
              <li>
                <div className="bdLeft">
                  <button className="addNewBtn" onClick={handleShow}>
                    <img src="/images/addNewBank.svg" alt="addNewBtn" /> + Add
                    New Bank
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="whtBox mt20">
          <div className="wallDetailWrp">
            <h3 className="sbTtl">Linked Cards</h3>
            <ul className="bankDet cardDet">


              {cardData?.loading ? [...Array(5)].map((d, i) => {
                return (
                  <li>
                    <div style={{ width: "20rem" }}>
                      <Skeleton height={20} />
                    </div>
                  </li>
                )
              }) : cardData?.data?.length > 0 ? cardData?.data?.map((data: any, i: number) => {
                return (
                  <li>
                    <div className="bdLeft">
                      {/* <div className="bank_card">
                        <img src="/images/citi_logo.png" alt="bank" />
                      </div> */}

                      <div className="cardDesc">
                        <button onClick={() => { setCardDetails(data); handleShow5(); }}>
                          <p className="crdNumb">Credit Card - {data?.last4}
                            {/* <img src="/images/visa.png" alt="cardType" /> */}
                          </p>
                        </button>
                        <p className="bnk">{data.exp_month}/{data.exp_year}</p>
                      </div>
                      {data?.default ? <div className="date flexBox itemCenter" style={{ marginLeft: "1rem", height: "1.5rem", fontSize: "12px", top: "1px" }}>Default</div> : ""}

                    </div>
                    {/* <div className="bdRight">
                      <button className="delBdet" onClick={handleShow2}>
                        <img src="/images/deleteIcon.svg" alt="delete" />
                      </button>
                    </div> */}
                  </li>
                )
              }) : "No Card found..."}

              <li>
                <div className="bdLeft">
                  <button className="addNewBtn" onClick={handleShow3}>
                    <img src="/images/addNewCard.svg" alt="addNewBtn" /> + Add
                    New Card
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>


      {/* Modals to add bank details*/}
      <Modal show={show}>
        <AddBank
          onClose={handleClose}
          setBankLoader={setBankLoader}
          bankLoader={bankLoader}
        />
      </Modal>

      {/* Modal for card delete */}
      <Modal show={show2}>
        <DeleteCard onClose={handleClose2} />
      </Modal>

      {/* Modals to add card details*/}
      <Modal show={show3}>
        <AddCard
          onClose={handleClose3}
          setCardLoader={setCardLoader}
          cardLoader={cardLoader} />
      </Modal>

      {/* Modals to show bank details*/}
      <Modal show={show4}>
        <BankDetail
          bankDetails={bankDetails}
          onClose={handleClose4}
          setBankLoader={setBankLoader}
          bankLoader={bankLoader}
        />
      </Modal>

      {/* Modals to show card details*/}
      <Modal show={show5}>
        <CardDetail
          cardDetails={cardDetails}
          onClose={handleClose5}
          setCardLoader={setCardLoader}
          cardLoader={cardLoader}
        />
      </Modal>

      {/* Modals to send payment */}
      <Modal show={show6}>
        <SendPayment
          onClose={handleClose6}
          setWalletLoader={setWalletLoader}
          walletLoader={walletLoader}
        />
      </Modal>

      {/* Modals to add money to wallet */}
      <Modal show={show7}>
        <AddMoneyToWallet
          onClose={handleClose7}
          setWalletLoader={setWalletLoader}
          walletLoader={walletLoader}
        />
      </Modal>

      {/* Modals to withdraw money to bank */}
      <Modal show={show8}>
        <WithdrawMoneyToBank
          onClose={handleClose8}
          setWalletLoader={setWalletLoader}
          walletLoader={walletLoader}
        />
      </Modal>

      {/* Modals to send payment */}
      <Modal show={show9}>
        <RequestMoney
          onClose={handleClose9}
          setWalletLoader={setWalletLoader}
          walletLoader={walletLoader}
        />
      </Modal>

    </>
  );
};

export default WalletBank;
