import { merchantSignupInterface, adminLoginInterface, forgetPasswordInterface, verifyAnswerInterface, verifyForgotPasswordOTPInterface, merchantProfileInterface } from "./interface/auth.interface"
import walletInstance from "./instances/wallet.instance"
import { removeUser } from "./token.service";
import axios from 'axios'



// _____________________________Activate Wallet __________________________________

async function activateMerchantWallet(data: any) {
  let response = await walletInstance.post(`/merchant/activate-wallet`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Add Bank Details __________________________________

async function addMerchantBank(data: any) {
  // const { userId, status } = params
  let response = await walletInstance.post(`/merchant/add/bank`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Get Banks List __________________________________

async function getMerchantBank() {
  let response = await walletInstance.get(`/merchant/banks`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}

// _____________________________Set as Default Bank  __________________________________

async function setMerchantBankDefault(data: any) {
  let response = await walletInstance.post(`/merchant/set-default-account`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Delete Bank __________________________________

async function deleteMerchantBank(data: any) {
  let response = await walletInstance.delete(`/merchant/bank`, { data });
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Get Cards List __________________________________

async function getMerchantCard(data: any) {
  let response = await walletInstance.post(`/merchant/cards`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________Set as Default Card  __________________________________

async function setMerchantCardDefault(data: any) {
  let response = await walletInstance.post(`/merchant/payment-methods/default`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Delete Card __________________________________

async function deleteMerchantCard(data: any) {
  let response = await walletInstance.delete(`/merchant/remove-card`, { data });
  try {
    return response.data
  } catch (err) {
    return err
  }
}






// _____________________________ Get Wallet Balance __________________________________

async function getMerchantWalletBalance() {
  let response = await walletInstance.get(`/merchant/wallet/balance`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}

// _____________________________ Send Wallet Balance __________________________________

async function sendMerchantMoney(data: any) {
  let response = await walletInstance.post(`/merchant/transfer-money`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}



// _____________________________ Add money to merchant wallet __________________________________

async function addMoneyToMerchantWallet(data: any) {
  let response = await walletInstance.post(`/merchant/add-money`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Add money to merchant wallet __________________________________

async function withdrawFundIntoMerchantBank(data: any) {
  let response = await walletInstance.post(`/merchant/withdraw`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Get Wallet Balance __________________________________

async function getMerchantTransaction(params: any) {
  const { page_size, page } = params
  let response = await walletInstance.get(`/merchant/transactions?page=${page}`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Get Merchant's User For transaction __________________________________

async function getMerchantUserTransaction(params: any) {
  const { page_size, page } = params
  let response = await walletInstance.get(`/merchant/user-management?page=${page}`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Unblock Merchant's User For transaction __________________________________

async function unblockMerchantUserTransaction(data: any) {
  const { id } = data
  let response = await walletInstance.delete(`/merchant/unblock-user/${id}`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Block Merchant's User For transaction __________________________________

async function blockMerchantUserTransaction(data: any) {
  const { id } = data
  let response = await walletInstance.get(`/merchant/block-user/${id}`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Block Merchant's User For transaction __________________________________

async function getMerchantDashboard() {
  let response = await walletInstance.get(`/merchant/dashboard`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ get merchant send request  __________________________________

async function getMerchantSendRequest(param: any) {
  const { page, page_size } = param
  let response = await walletInstance.get(`/merchant/money-requests?limit=${page_size}&skip=${page}`);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


// _____________________________ Send Wallet Balance __________________________________

async function sendMerchantRequestMoney(data: any) {
  let response = await walletInstance.post(`/merchant/request-money`, data);
  try {
    return response.data
  } catch (err) {
    return err
  }
}



// _____________________________ merchant money request action __________________________________

async function merchantRequestMoneyAction(params: any) {
  const { id, action } = params
  let response = await walletInstance.get(`/merchant/approve/money-requests/${id}?action=${action}`,);
  try {
    return response.data
  } catch (err) {
    return err
  }
}


const walletService = {
  activateMerchantWallet,
  addMerchantBank,
  getMerchantBank,
  setMerchantBankDefault,
  deleteMerchantBank,
  getMerchantCard,
  setMerchantCardDefault,
  deleteMerchantCard,
  getMerchantWalletBalance,
  sendMerchantMoney,
  addMoneyToMerchantWallet,
  withdrawFundIntoMerchantBank,
  getMerchantTransaction,
  getMerchantUserTransaction,
  blockMerchantUserTransaction,
  unblockMerchantUserTransaction,
  getMerchantDashboard,
  getMerchantSendRequest,
  sendMerchantRequestMoney,
  merchantRequestMoneyAction,
};

export default walletService;
