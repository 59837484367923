import { createSlice } from "@reduxjs/toolkit"
import { getMerchantTransactions } from './transactionAction'


interface transactionStateinterface {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: null
}



interface PayloadAction {
    payload: any
}

const initialState: transactionStateinterface = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: null
}


const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMerchantTransactions.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(getMerchantTransactions.fulfilled, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload

            })
            .addCase(getMerchantTransactions.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
    },
})

export default transactionSlice.reducer