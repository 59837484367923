import React, { useEffect, useState } from 'react'
import LeftMenu from '../../../component/Merchant/MerchantLeftMenu';
import Receipt from '../../../component/Receipt';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import { getMerchantTransactions } from '../../../redux/features/transaction/transactionAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../../redux/store"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { convertUTCDateTimetoLocal } from "../../../utils/timeConverter"
import ReactPaginate from 'react-paginate';
import { useDebounce } from 'use-debounce';

const initialParamState: any = {
    page: 1,
    search: "",
    page_size: 10,
    status: ""
};

const Transactions = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isActive, setIsActive] = useState(false);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };
    const [singleDate, setSingleDate] = useState<Date | null>(new Date());
    const dispatch: AppDispatch = useDispatch()
    const transactionData = useSelector((state: any) => state.transaction)
    const [perPage, setPerPage] = useState(10)
    const [paramState, setParamState] = useState(initialParamState);
    const [viewReceiptData, setViewReceiptData] = useState();
    const { page, page_size, search, status } = paramState;
    const [value] = useDebounce(search, 300);



    useEffect(() => {
        let params = { page, page_size };
        dispatch(getMerchantTransactions(params))

    }, [])

    const handlePageChange = async ({ selected }: any) => {
        let selectedPage = selected + 1;
        setPerPage(10 * Number(selectedPage));
        let params: any = { page: selectedPage, page_size };
        await dispatch(getMerchantTransactions(params));
        setParamState({
            page: selectedPage,
            page_size,
        })
    };


    return (
        <>
            <div className="dbRight">
                <div className="mainHd">Transactions</div>
                <div className="whtBox">
                    <div className="flexBox itemCenter justifyContentBetween">
                        <div className="totalNumber">Total:  {transactionData?.data?.total}</div>
                        <ul className="flexBox filter transactionsFilter">
                            {/* <li>
                                <div className="filterSearch pr"><input type="text" placeholder="Search by Name, Trx ID"
                                    className="filterInput" /> <span className="material-icons icon">search</span></div>
                            </li> */}
                            {/* <li>
                                <div className="filterDate pr"><DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} /> <span
                                    className="material-icons icon">calendar_month</span></div>
                            </li> */}
                            {/* <li>
                                <select className="filterSelect">
                                    <option>Payment Source</option>
                                </select>
                            </li> */}
                            {/* <li>
                                <select className="filterSelect">
                                    <option>Status</option>
                                </select>
                            </li> */}
                        </ul>
                    </div>
                    <div className="dbTable">
                        <table>
                            <tr>
                                <th>Sr. No.</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Trx ID.</th>
                                <th>Payment Source</th>
                                <th>Date & Time</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            {transactionData?.loading ?
                                [...Array(10)].map((data, i) => {
                                    return (
                                        <>
                                            <tr key={i}>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                                <td><Skeleton /></td>
                                            </tr>
                                        </>

                                    )
                                })
                                :
                                transactionData?.data?.data?.map((data: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1 + (perPage - 10)}</td>
                                            <td>{data?.payer_name}</td>
                                            <td>{data?.payee_name}</td>
                                            <td>{data?.id}</td>
                                            <td>{data?.payment_method?.toUpperCase()}</td>
                                            <td>{convertUTCDateTimetoLocal(data?.created_at)}</td>
                                            <td>${data?.payment_amount}</td>
                                            <td><span className={`status ${data?.status.toLowerCase()}`}>{data?.status}</span></td>
                                            <td><a className="viewLink" onClick={() => { handleShow(); setViewReceiptData(data) }}>View Receipt</a></td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
                <div className="flexBox itemCenter justifyContentBetween">
                    <ReactPaginate
                        pageCount={Math.ceil(
                            transactionData?.data?.total / 10
                        )}
                        onPageChange={handlePageChange}
                        containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                        activeClassName={"active"}
                        previousLabel={"<"}
                        nextLabel={">"}
                    />
                </div>

                <Modal show={show}>
                    <Receipt onClose={handleClose} data={viewReceiptData} />
                </Modal>


            </div >
        </>

    )
}

export default Transactions