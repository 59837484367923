import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import walletService from '../services/walletService';
interface BlockUserProps {
  onClose: () => void;
  data?: any;
  reload?: any
  setReload?: any
}

const BlockUser: React.FC<BlockUserProps> = ({ onClose, data, reload, setReload }) => {

  const {
    payer_id,
    block_status
  } = data
  const [isBlockBTNLoading, setIsBlockBTNLoading] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [actionValue, setActionValue] = useState("")
  const [valueError, setValueError] = useState("")
  const handleModalClose = () => {
    onClose();
  };

  const handleBlock = () => {
    setIsBlockBTNLoading(true)
    let data = {
      id: payer_id
    }
    walletService.blockMerchantUserTransaction(data).then((res: any) => {
      setIsBlockBTNLoading(false)
      if (!res.error) {
        setVisible(!visible)
        setReload(!reload)
      }
    }).catch((err) => {
      setIsBlockBTNLoading(false)

    })
  }

  const handleUnBlock = () => {
    setIsBlockBTNLoading(true)
    let data = {
      id: payer_id
    }
    walletService.unblockMerchantUserTransaction(data).then((res: any) => {
      setIsBlockBTNLoading(false)
      if (!res.error) {
        setVisible(!visible)
        setReload(!reload)

      }
    }).catch((err) => {
      setIsBlockBTNLoading(false)

    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (actionValue.toLowerCase() === "yes") {
      if (block_status) {
        handleUnBlock()
      } else {
        handleBlock()
      }
    } else {

    }
  }


  return (
    <>
      <div>
        <div className="popupBox" style={{ display: visible ? 'none' : 'block' }}>
          <a className="closePopup" onClick={handleModalClose}>
            <span className="material-icons">cancel</span>
          </a>
          <div className="popupHd tac">{block_status ? "UnBlock" : "Block"} User</div>
          <div className="tac loginText">Are you sure you want to {block_status ? "unblock" : "block"} the user? For confirmation please enter the text <strong>"Yes"</strong></div>
          <form onSubmit={handleSubmit} className="loginForm p-0 m-0">
            <li>
              <input type="text" placeholder="Enter" className="formInput" onChange={(e: any) => setActionValue(e.target.value)} />
              <div className="erroeMessage" style={{ display: "none" }}>Incorrect confirmation text.</div>
            </li>
            <li>
              <Button disabled={isBlockBTNLoading} className="loginBtn block" type="submit">{isBlockBTNLoading ? "Loading..." : block_status ? "UnBlock" : "Block"}</Button>
            </li>
          </form>
        </div>
        <div className="popupBox" style={{ display: visible ? 'block' : 'none' }}>
          <a className="closePopup" onClick={handleModalClose}><span className="material-icons">cancel</span></a>
          <div className="tac sucessfull"><span className="material-icons-outlined">verified</span></div>
          <div className="popupHd tac">User {block_status ? "UnBlock" : "Block"} Successfully!</div>
        </div>
      </div>
    </>
  )
}

export default BlockUser