import React, { useState, Dispatch, SetStateAction } from 'react';
import Button from 'react-bootstrap/Button';
import { addBankInitailState } from "../../../services/interface/bank.interface"
import walletService from "../../../services/walletService"
import PopUp from "../../../component/PopUp";
import Modal from "react-bootstrap/Modal";

interface UpdateProps {
  onClose: () => void;
  setBankLoader: Dispatch<SetStateAction<boolean>>;
  bankLoader: boolean
}

const initialState: addBankInitailState = {
  object: "bank_account",
  country: "",
  currency: "",
  account_number: "",
  routing_number: "",
  account_holder_name: "",
  account_holder_type: ""
}

const AddBank: React.FC<UpdateProps> = ({ onClose, setBankLoader, bankLoader }) => {
  const [modalStep, setModalStep] = React.useState<number>(1);
  const [iState, updateState] = useState<addBankInitailState>(initialState)
  const [isloading, setIsloading] = useState<boolean>(false)
  const [popup, setPopup] = useState({ show: false, message: "" })
  const [error, setError] = useState<any>({})

  const { country, currency, account_number, routing_number, account_holder_name, account_holder_type } = iState

  const handleClose = () => {
    onClose();
  };

  const changeBankAccountForm = (e: any) => {
    const { value, name } = e.target
    updateState({
      ...iState,
      [name]: value
    })
  }

  // handle form validation
  let handleValidation = () => {
    let countryEmpty
    let currencyEmpty
    let accountNumberEmpty
    let routingNumberEmpty
    let accountHolderNameEmpty
    let accountHolderTypeEmpty
    let formIsValid = true;

    if (!country.trim()) {
      countryEmpty = "Please select country.";
      formIsValid = false;
    }

    if (!currency.trim()) {
      currencyEmpty = "Please select currency.";
      formIsValid = false;
    }

    if (!account_number.trim()) {
      accountNumberEmpty = "Please enter account no.";
      formIsValid = false;
    }

    if (!routing_number.trim()) {
      routingNumberEmpty = "Please enter routing no.";
      formIsValid = false;
    }

    if (!account_holder_name.trim()) {
      accountHolderNameEmpty = "Please enter account holder name.";
      formIsValid = false;
    }

    if (!account_holder_type.trim()) {
      accountHolderTypeEmpty = "Please enter account holder type.";
      formIsValid = false;
    }

    setError({
      countryEmpty,
      currencyEmpty,
      accountNumberEmpty,
      routingNumberEmpty,
      accountHolderNameEmpty,
      accountHolderTypeEmpty,
      formIsValid,
    });
    return formIsValid;
  };

  const handleBankAccountSubmit = (e: any) => {
    e.preventDefault()
    const isValid = handleValidation()
    if (!isValid) {
      return
    }
    setIsloading(true)
    walletService.addMerchantBank(iState).then((res) => {
      handleClose()
      setPopup({ show: true, message: "Submit successfully" })
      setIsloading(false)
      setBankLoader(!bankLoader)
    }).catch((err) => {
      setPopup({ show: true, message: err?.response?.data?.message })
      setIsloading(false)
    })
  }

  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Add Bank</div>
            <div className="tac loginText">Please provide the bank details</div>
            <form onSubmit={handleBankAccountSubmit} onChange={changeBankAccountForm} >
              <ul className="loginForm m-0 p-0">
                <li className="frmGrp">
                  <select defaultValue={country} name="country" className="formInput">
                    <option value="">Select Country*</option>
                    <option value="MY">Malaysia</option>
                  </select>
                  <span style={{ color: "red" }}>
                    {error?.countryEmpty}
                  </span>
                </li>

                <li className="frmGrp">
                  <select defaultValue={currency} name="currency" id="" className="formInput">
                    <option value="">Select Currency*</option>
                    <option value="myr">Malaysian Ringgit</option>
                  </select>
                  <span style={{ color: "red" }}>
                    {error?.currencyEmpty}
                  </span>
                </li>
                <li>
                  <input defaultValue={account_number} name="account_number" type="text" placeholder="Account Number*" className="formInput" />
                  <span style={{ color: "red" }}>
                    {error?.accountNumberEmpty}
                  </span>
                </li>
                <li>
                  <input defaultValue={routing_number} name="routing_number" type="text" placeholder="Routing Number*" className="formInput" />
                  <span style={{ color: "red" }}>
                    {error?.routingNumberEmpty}
                  </span>
                </li>
                <li>
                  <input defaultValue={account_holder_name} name="account_holder_name" type="text" placeholder="Account Holder Name*" className="formInput" />
                  <span style={{ color: "red" }}>
                    {error?.accountHolderNameEmpty}
                  </span>
                </li>
                <li className="frmGrp">
                  <select defaultValue={account_holder_type} name="account_holder_type" className="formInput">
                    <option value="">Select Account Holder Type*</option>
                    <option value="individual">Individual</option>
                  </select>
                  <span style={{ color: "red" }}>
                    {error?.accountHolderTypeEmpty}
                  </span>
                </li>
                <li>
                  <Button disabled={isloading} className="loginBtn" type="submit">{isloading ? "Loading..." : "Add Bank"}</Button>
                </li>
              </ul>
            </form>
          </div>
        )}

        {/* {modalStep === 2 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Security Verification</div>
            <div className="tac loginText otp">Enter the OTP sent to your registered mobile number with your bank.</div>
            <div className="otpWrp">
              <form action="">
                <div className="otpSec">
                  <input type="text" className="formInput" />
                  <input type="text" className="formInput" />
                  <input type="text" className="formInput" />
                  <input type="text" className="formInput" />
                </div>
              </form>
            </div>
            <Button className="loginBtn" onClick={handleNextStep}>Verify</Button>
            <p className="dint">
              Didn’t Receive? <a href="#" className="link">Send Again</a>
            </p>
          </div>
        )}

        {modalStep === 3 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="tac sucessfull">
              <span className="material-icons-outlined">verified</span>
            </div>
            <div className="popupHd tac">Bank Added Successfully!</div>
            <Button className="loginBtn" onClick={handleClose}>Close</Button>
          </div>
        )} */}
      </div>
      <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
    </>
  );
};

export default AddBank;
