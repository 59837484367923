import { merchantProfileInterface } from "services/interface/auth.interface";
import walletService from "../../../services/walletService"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface AsyncFunctionParams {
  rejectWithValue: (value: any) => void;
}




// --------------------------------Get Merchant Wallet Balance---------------------------------

export const getMerchantWalletBalance = createAsyncThunk(
  'BW/getMerchantWalletBalance',
  async (_, params: AsyncFunctionParams) => {
    try {
      const response: any = await walletService.getMerchantWalletBalance();
      return response
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return params.rejectWithValue(error.response.data.message)
      } else {
        return params.rejectWithValue(error.message)
      }
    }
  }
)











// --------------------------------Get Admin Profile Details---------------------------------

// export const getAdminProfile = createAsyncThunk(
//   'profile/getAdminProfile',
//   async (_, params: AsyncFunctionParams) => {
//     try {
//       const response: any = await walletService.getAdminProfileDetails();
//       return response
//     } catch (error: any) {
//       if (error.response && error.response.data.message) {
//         return params.rejectWithValue(error.response.data.message)
//       } else {
//         return params.rejectWithValue(error.message)
//       }
//     }
//   }
// )


// --------------------------------Add Admin Profile Details---------------------------------

// export const setAdminProfile = createAsyncThunk(
//   'profile/setAdminProfile',
//   async (data: any, params: AsyncFunctionParams) => {
//     try {
//       const response: any = await walletService.setAdminProfileDetails(data);
//       return response
//     } catch (error: any) {
//       if (error.response && error.response.data.message) {
//         return params.rejectWithValue(error.response.data.message)
//       } else {
//         return params.rejectWithValue(error.message)
//       }
//     }
//   }
// )