import { createSlice } from "@reduxjs/toolkit"
import { getMerchantCards } from './cardAction'


interface BWStateinterface {
    loading: boolean
    message: string
    success: boolean
    error: boolean
    data: null
}

interface PayloadAction {
    payload: any
}

const initialState: BWStateinterface = {
    loading: false,
    message: "",
    success: false,
    error: false,
    data: null

}

const BWSlice = createSlice({
    name: 'bankAndWallet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMerchantCards.pending, (state) => {
                state.loading = true;
                state.message = "";
                state.success = false;
                state.error = false
                state.data = null
            })
            .addCase(getMerchantCards.fulfilled, (state, action: PayloadAction) => {                
                state.loading = false;
                state.message = action.payload.message
                state.success = true;
                state.error = false
                state.data = action.payload.data
            })
            .addCase(getMerchantCards.rejected, (state, action: PayloadAction) => {
                state.loading = false;
                state.message = action.payload
                state.success = false;
                state.error = true
                state.data = null

            })
    },
})

export default BWSlice.reducer