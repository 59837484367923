import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { sendPaymentInterface } from "../../../services/interface/bank.interface"
import walletService from "../../../services/walletService"
import PopUp from "../../../component/PopUp";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import Form from 'react-bootstrap/Form';

interface UpdateProps {
  onClose: () => void;
  setWalletLoader: Dispatch<SetStateAction<boolean>>;
  walletLoader: boolean
}

const initialState: sendPaymentInterface = {
  destination_phone_number: "",
  amount: ""
}

const SendPayment: React.FC<UpdateProps> = ({ onClose, setWalletLoader, walletLoader }) => {
  const [modalStep, setModalStep] = React.useState<number>(1);
  const [iState, updateState] = useState<sendPaymentInterface>(initialState)
  const [isloading, setIsloading] = useState<boolean>(false)
  const [popup, setPopup] = useState({ show: false, message: "" })
  const [error, setError] = useState<any>({})
  const [selectedCard, setSelectedCard] = useState<string>("")

  const { amount, destination_phone_number } = iState

  const cardData = useSelector((state: any) => state.card)

  useEffect(() => {
    cardData?.data?.map((data: any) => {
      setSelectedCard(data.id)
    })
  }, [])

  const handleClose = () => {
    onClose();
  };

  const changeBankAccountForm = (e: any) => {
    const { value, name } = e.target
    const regex = /^\d+$/;
    const amountRegex = /^$|^[1-9]\d*(\.\d{1,2})?$/;

    if (name === "destination_phone_number" && regex.test(value)) {
      updateState({
        ...iState,
        [name]: value
      })
    }

    if (name === "amount" && amountRegex.test(value)) {
      updateState({
        ...iState,
        [name]: value
      })
    }


  }

  // handle form validation
  let handleValidation = () => {
    let amountEmpty
    let formIsValid = true;

    if (!amount.trim()) {
      amountEmpty = "Please enter amount.";
      formIsValid = false;
    }

    setError({
      amountEmpty,
      formIsValid,
    });
    return formIsValid;
  };

  const handleSendMoney = (e: any) => {
    e.preventDefault()
    const isValid = handleValidation()
    if (!isValid) {
      return
    }

    setModalStep(2)

    // setIsloading(true)

    // const payload = {
    //   destination_phone_number,
    //   amount: Number(amount)
    // }

    // walletService.sendMerchantMoney(payload).then((res) => {
    //   setIsloading(false)
    //   if (res.status === "success") {
    //     setWalletLoader(!walletLoader)
    //     setModalStep(2)
    //   }
    // }).catch((err) => {
    //   setPopup({ show: true, message: err?.response?.data?.message })
    //   setIsloading(false)
    // })
  }

  const addMoneyToWallet = () => {
    setIsloading(true)
    const payload = {
      amount,
      payment_method_id: selectedCard,
      customer_id: localStorage.getItem("customerId")
    }

    walletService.addMoneyToMerchantWallet(payload).then((res) => {
      setIsloading(false)
      if (res.status === "success") {
        setWalletLoader(!walletLoader)
        setModalStep(3)
      }
    }).catch((err) => {
      setPopup({ show: true, message: err?.response?.data?.message })
      setIsloading(false)
    })


  }

  const handleSelectCard = (id: string) => {
    setSelectedCard(id)
  }

  return (
    <>
      <div>
        {modalStep === 1 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Add Money To Wallet</div>
            <form onSubmit={handleSendMoney} onChange={changeBankAccountForm} >
              <ul className="loginForm m-0 p-0">

                {/* <li>
                  <input value={destination_phone_number} name="destination_phone_number" type="text" placeholder="Enter Email or Number" className="formInput" />
                  <span style={{ color: "red" }}>
                    {error?.destinationPhoneNumberEmpty}
                  </span>
                </li> */}

                <li>
                  <input value={amount} name="amount" type="text" placeholder="Enter Amount" className="formInput" />
                  <span style={{ color: "red" }}>
                    {error?.amountEmpty}
                  </span>
                </li>

                <li>
                  <Button disabled={isloading} className="loginBtn" type="submit">{isloading ? "Loading..." : "Proceed Securely"}</Button>
                </li>
              </ul>
            </form>
          </div>
        )}

        {modalStep === 2 && (
          <div className="popupBox" style={{ padding: "60px 80px" }}>
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="popupHd tac">Add Money To Wallet</div>
            <div className="tac">Amount : ${amount}</div>
            <div className="whtBox mt20">
              <div className="wallDetailWrp">
                <h3 className="sbTtl">Linked Cards</h3>
                <ul className="bankDet cardDet">


                  {cardData?.loading ? [...Array(5)].map((d, i) => {
                    return (
                      <li>
                        <div style={{ width: "20rem" }}>
                          <Skeleton height={20} />
                        </div>
                      </li>
                    )
                  }) : cardData?.data?.length > 0 ? cardData?.data?.map((data: any, i: number) => {
                    return (
                      <li>
                        <div className="bdLeft">
                          {/* <div className="bank_card">
                        <img src="/images/citi_logo.png" alt="bank" />
                        </div> */}

                          <div className="cardDesc">
                            {/* <div className="rem"> */}
                            <label className="d-flex" style={{ cursor: "pointer" }}>
                              <input type="radio" onChange={() => handleSelectCard(data?.id)} checked={data?.id === selectedCard} style={{ marginRight: "10px" }} />
                              {/* </div> */}
                              {/* <button onClick={() => { setCardDetails(data); handleShow5(); }}> */}
                              <div>

                                <p className="crdNumb">Credit Card - {data?.last4}
                                  {/* <img src="/images/visa.png" alt="cardType" /> */}
                                </p>
                                {/* </button> */}
                                {/* <p className="bnk">{data.exp_month}/{data.exp_year}</p> */}
                              </div>
                            </label>
                          </div>
                          {/* {data?.default ? <div className="date flexBox itemCenter" style={{ marginLeft: "1rem", height: "1.5rem", fontSize: "12px", top: "1px" }}>Default</div> : ""} */}

                        </div>
                        {/* <div className="bdRight">
                      <button className="delBdet" onClick={handleShow2}>
                        <img src="/images/deleteIcon.svg" alt="delete" />
                      </button>
                    </div> */}
                      </li>
                    )
                  }) : "No Card found..."}
                </ul>
              </div>
            </div>
            <Button disabled={isloading} className="loginBtn mt-4" onClick={addMoneyToWallet}>{isloading ? "Loading..." : "Proceed Securely"}</Button>

          </div>
        )}

        {modalStep === 3 && (
          <div className="popupBox" style={{ padding: "80px" }}>
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "12px",
                background: "#44B678",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  color: "#FFFFFF",
                  fontSize: "20px",
                  marginTop: "35px"
                }}
              >
                Payment Sent Successfully
              </p>
              <h1
                style={{
                  fontSize: "50px",
                  fontWeight: "normal",
                  color: "#FFFFFF"
                }}
              >${amount}</h1>
            </div>

            <div
              style={{
                height: "470px",
                borderRadius: "12px",
                background: "#FFFFFF",
                boxSizing: "border-box",
                border: "1px solid #DEDEDE",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
                marginTop: "15px",
                padding: "25px 20px"
              }}
            >

              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ lineHeight: "1.5" }}>
                  <div>To:</div>
                  <div style={{ fontSize: "18px" }}>Devon Lane</div>
                  <div>EazyPay Wallet</div>
                  <div>(+65) 555-0127</div>
                </div>
                <div>
                  <img src="/images/chat_bot.png" height={45} alt="receiver img" />
                </div>
              </div>

              <div style={{
                height: "1px",
                background: "#DCDCDC",
                margin: "25px 0"
              }} ></div>

              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ lineHeight: "1.5" }}>
                  <div>From:</div>
                  <div style={{ fontSize: "18px" }}>Devon Lane</div>
                  <div>EazyPay Wallet</div>
                  <div>(+65) 555-0127</div>
                </div>
                <div>
                  <img src="/images/user-icon.png" height={45} alt="receiver img" />
                </div>
              </div>

              <div style={{ lineHeight: "1.5", marginTop: "15px" }}>
                <div>Transaction ID: 21365479541236</div>
                <div style={{ color: "#8A8A8D" }}>12 Mar, 2024  |  10:30 AM</div>
              </div>

              <div style={{
                height: "1px",
                background: "#DCDCDC",
                margin: "25px 0"
              }} ></div>

              <div className='d-flex justify-content-center'>
                <img src="/images/mini-logo.png" alt="logo" />
              </div>

            </div>

            <div className='d-flex justify-content-center' style={{ position: "absolute", bottom: "30px", left: "170px" }}>
              <img src="/images/secure.png" alt="100% secure payment" />
            </div>

          </div>
        )}

        {/* {modalStep === 3 && (
          <div className="popupBox">
            <a className="closePopup" onClick={handleClose}>
              <span className="material-icons">cancel</span>
            </a>
            <div className="tac sucessfull">
              <span className="material-icons-outlined">verified</span>
            </div>
            <div className="popupHd tac">Bank Added Successfully!</div>
            <Button className="loginBtn" onClick={handleClose}>Close</Button>
          </div>
        )} */}
      </div>
      <Modal show={popup.show}><PopUp message={popup.message} onClose={() => setPopup({ show: false, message: "" })} /></Modal>
    </>
  );
};

export default SendPayment;
