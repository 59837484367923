import React, { useState, useEffect } from 'react'
import Header from '../../../component/Admin/AdminHeader';
import LeftMenu from '../../../component/Merchant/MerchantLeftMenu';
// import UserDetail from '../../../component/UserDetail';
import BlockUser from '../../../component/BlockUser';
import UnblockUser from '../../../component/UnblockUser';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";

import { getMerchantUserTransaction } from '../../../redux/features/UsersTransaction/userTransactionAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../../redux/store"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { convertUTCDateTimetoLocal } from "../../../utils/timeConverter"
import ReactPaginate from 'react-paginate';
import { useDebounce } from 'use-debounce';
import walletService from '../../../services/walletService';

const initialParamState: any = {
  page: 1,
  search: "",
  page_size: 10,
  status: ""
};


const UserManagement = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [isActive, setIsActive] = useState(false);
  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const [singleDate, setSingleDate] = useState<Date | null>(new Date());

  const dispatch: AppDispatch = useDispatch()
  const userTransactionData = useSelector((state: any) => state.userTransaction)

  const [perPage, setPerPage] = useState(10)
  const [paramState, setParamState] = useState(initialParamState);
  const [viewReceiptData, setViewReceiptData] = useState();
  const [blockFuncId, setBlockFuncId] = useState("");
  const [reload, setReload] = useState("");
  const { page, page_size, search, status } = paramState;
  const [value] = useDebounce(search, 300);



  useEffect(() => {
    let params = { page, page_size };
    dispatch(getMerchantUserTransaction(params))
  }, [reload])

  const handlePageChange = async ({ selected }: any) => {
    let selectedPage = selected + 1;
    setPerPage(10 * Number(selectedPage));
    let params: any = { page: selectedPage, page_size };
    await dispatch(getMerchantUserTransaction(params));
    setParamState({
      page: selectedPage,
      page_size,
    })
  };

  return (
    <>
      <div className="dbRight">
        <div className="mainHd">User Management</div>
        <div className="whtBox">
          <div className="flexBox itemCenter justifyContentBetween">
            <div className="totalNumber">Total: 150</div>
            <ul className="flexBox filter">
              {/* <li>
                <div className="filterSearch pr"><input type="text" placeholder="Search by Name, Trx ID"
                  className="filterInput" /> <span className="material-icons icon">search</span></div>
              </li> */}
              {/* <li>
                <div className="filterDate pr">
                  <DatePicker className='filterInput datepicker' selected={singleDate} onChange={(date) => setSingleDate(date)} />
                  <span className="material-icons icon">calendar_month</span>
                </div>
              </li> */}
              {/* <li>
                <select className="filterSelect">
                  <option>All</option>
                </select>
              </li> */}
            </ul>
          </div>
          <div className="dbTable">
            <table>
              <tr>
                <th>Sr. No.</th>
                <th>User Name</th>
                <th>User ID</th>
                <th>Last Transaction Date</th>
                <th>Total Transaction Amount</th>
                <th>Phone Number</th>
                <th>Action</th>
              </tr>
              {userTransactionData?.loading ?
                [...Array(10)].map((data, i) => {
                  console.log(data)
                  return (
                    <>
                      <tr key={i}>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                      </tr>
                    </>

                  )
                })
                :
                userTransactionData?.data?.data?.map((data: any, i: number) => {
                  console.log("data : ", data)
                  return (
                    <tr key={i}>
                      <td>{i + 1 + (perPage - 10)}</td>
                      <td>{data?.payer_name}</td>
                      <td>{data?.payer_id}</td>
                      <td>{convertUTCDateTimetoLocal(data?.latest_transaction_date)}</td>
                      <td>${data?.total_payment_amount}</td>
                      <td>{data?.payer_phone_number}</td>
                      <td>
                        <div className="flexBox nowrap">
                          <a className="viewLink mr10" onClick={() => { handleShow2(); setBlockFuncId(data) }}>{data?.block_status ? "Unblock" : "Block"}</a>
                          {/* <a className="viewLink" onClick={() => { handleShow(); setViewReceiptData(data) }}>View Receipt</a> */}
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
              {/* <tr>
                <td>10</td>
                <td>Delilah Morales</td>
                <td>123ABC456</td>
                <td>03/20/2024</td>
                <td>$4850.00</td>
                <td className="nowrap">+65 8901 2345</td>
                <td>
                  <div className="flexBox nowrap">
                    <a className="viewLink mr10" onClick={handleShow2}>Block</a>
                    <a className="viewLink" onClick={handleShow}>View Details</a>
                  </div>
                </td>
              </tr> */}
            </table>
          </div>
        </div>
        <div className="flexBox itemCenter justifyContentBetween">
          <ReactPaginate
            pageCount={Math.ceil(
              userTransactionData?.data?.total / 10
            )}
            onPageChange={handlePageChange}
            containerClassName={"paging m-0 p-0 pt-3 d-flex"}
            activeClassName={"active"}
            previousLabel={"<"}
            nextLabel={">"}
          />
        </div>
      </div>
      <Modal show={show}>
        {/* <UserDetail onClose={handleClose} /> */}
      </Modal>
      <Modal show={show2}>
        <BlockUser setReload={setReload} reload={reload} data={blockFuncId} onClose={handleClose2} />
      </Modal>
      <Modal show={show3}>
        <UnblockUser onClose={handleClose3} />
      </Modal>
    </>

  )
}

export default UserManagement