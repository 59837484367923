import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import profileReducer from './features/profile/profileSlice';
import disputeSlice from './features/dispute/disputeSlice'
import kycDetail from './features/KYC/kycDetailSlice'
import kycStatus from './features/KYC/kycStatusSlice'
import merchantKYCList from "./features/KYC/merchantKYCList"
import userKYCList from "./features/KYC/userKYCList"
import bank from "./features/bank/bankSlice"
import card from "./features/card/cardSlice"
import wallet from "./features/wallet/walletSlice"
import transaction from "./features/transaction/transactionSlice"
import userTransaction from "./features/UsersTransaction/userTransactionSlice"


export const store = configureStore({
    reducer: {
        auth: authReducer,
        profile: profileReducer,
        dispute: disputeSlice,
        kycDetail: kycDetail,
        kycStatus: kycStatus,
        merchantKYCList: merchantKYCList,
        userKYCList: userKYCList,
        bank: bank,
        card: card,
        wallet: wallet,
        transaction: transaction,
        userTransaction: userTransaction
    },
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
